.home-static {
  height: 375px;
  position: static;
}

iframe.social-li {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.home-relative {
  height: 375px;
  position: relative;
  top: 41px;
  right: 7%;
  bottom: auto;
  z-index: 1;
  padding-right: 20px;
}

img.img-half {
  width: 50%;
}

.hero.home {
  background-image: url("../assets/hero_bg_home.jpg");
}

.slider-nav-backdrop {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50px;
  text-align: center;
  margin-left: 10px;
}

.slider-nav-backdrop:hover {
  cursor: pointer;
}

.news-nav-next {
  font-size: 40px;
  font-weight: bolder;
  color: #d6112b;
}

.section.full.home {
  max-width: 100%;
  height: 400px;
  background-image: linear-gradient(180deg,
      rgba(214, 17, 43, 0.6),
      rgba(214, 17, 43, 0.6)),
    url("../assets/home_bg_1.jpg");
  background-size: auto, cover;
  background-position: 0 0, 50% 50%;
  background-repeat: repeat, no-repeat;
}

@media only screen and (max-width: 1100px) {
  .w-image.half {
    display: flex;
    justify-content: center;
  }

  .home-relative {
    display: none;
  }

  .home-static {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .w-image.half {
    display: none;
  }

  .bg-quote {
    font-size: 28px;
  }
}
