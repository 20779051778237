@media only screen and (max-width: 767px) {

  .third,
  .two-thirds {
    width: 100%;
  }

  .two-thirds {
    margin-bottom: 50px;
  }

  .contact-cont .two-thirds input.i-half {
    width: 98%;
  }

  .two-thirds div.full {
    flex-wrap: wrap;
  }

  a.news-cont {
    width: 48%;
    margin-right: 2%;
  }

  .news h1 {
    font-size: 26px;
  }

  .po-box div.third {
    flex-direction: column;
  }

  .po-box div img {
    max-height: 150px;
    min-height: 150px;
  }

  div.po-box {
    width: 100%;
  }

  .react-pdf__Document,
  .react-pdf__Page,
  .react-pdf__Page__canvas,
  .react-pdf__Page__textContent {
    margin: 0 auto;
    width: 100% !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 1100px) {
  .po-box .third.flex {
    width: 100%;
    margin: 1% 0;
  }

  .po-box-2 a.half {
    width: 100%;
    margin: 1% 0;
  }
}

.po-box-2 {
  width: 100%;
  margin-top: 20px;
}

.po-box-2 h2 {
  text-align: center;
  font-size: 32px;
}

.po-box-2 h3 {
  margin-top: 0;
  font-size: 24px;
}

.po-box img {
  max-height: 250px;
  min-height: 250px;
}

.po-box-2 .half {
  width: 44%;
  margin: 0 1% 2% 1%;
  flex-direction: column;
  display: flex;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.po-box .third {
  width: 30.33%;
  margin: 0 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.po-box .third.active {
  border: 1px solid #d6112b;
}

.news img {
  max-width: 300px;
}

.news h1 {
  margin-bottom: 10px;
}

.news p.body.desc {
  color: gray;
  margin-bottom: 20px;
}

.news .bottom-divider {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.contact-cont {
  margin-top: 40px;
  width: 100%;
}

.w-checkbox.checkbox-cont.flex.row {
  width: 95%;
  margin-top: 10px;
  flex-wrap: nowrap;
}

.w-checkbox.checkbox-cont.flex.row span {
  display: flex;
  align-items: center;
}

.two-thirds input,
.two-thirds textarea {
  font-family: "Lato Regular";
  display: block;
  height: 38px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.3;
  margin-right: 2%;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.news-cont {
  width: 18%;
  margin-right: 2%;
}

.news-contt {
  width: 100%;
}

.news-cont img {
  max-height: 100px;
}

.news-cont h4 {
  font-family: "Lato Regular";
  font-size: 16px;
  font-weight: 400;
}

p.body.error-msg {
  margin-bottom: 20px;
  color: #d6112b;
}

p.body.success-msg {
  margin-bottom: 20px;
  color: #32cd32;
}

.two-thirds input.i-half {
  margin-bottom: 10px;
  width: 48%;
}

.two-thirds input.i-full {
  margin-bottom: 10px;
}

.two-thirds input.i-newsletter-mail {
  width: 300px;
}

.two-thirds input.i-full,
.two-thirds textarea {
  width: 100%;
}

.two-thirds textarea {
  height: 100px;
}

.two-thirds div.full {
  width: 95%;
  display: flex;
}