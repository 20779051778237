.section.footer {
  max-width: 1200px;
  width: 100%;
}

.footer-third {
  width: 170px;
}

.footer-third a {
  text-decoration: none;
  color: #333;
  margin-bottom: 10px;
}

.footer-third a:hover {
  color: #d6112b;
}

.bottom-divider.footer {
  margin-bottom: 30px;
}

.footer-header {
  margin-bottom: 10px;
  color: #d6112b;
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .footer-third {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}
