nav {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: -70px;
  right: 0;
  background-color: #fff;
  z-index: 99;
}

.nav-lower .Dropdown-root .Dropdown-control {
  border: none;
  padding: 8px 10px 8px 10px;
}

img.nav-logo {
  margin-right: 10px;
}

.nav-lower .Dropdown-root .Dropdown-arrow-wrapper {
  display: none;
}

.nav-lower .Dropdown-menu {
  width: 235%;
}

.nav-lower .Dropdown-option {
  font-size: 16px;
  outline: none;
}

.marg-top-10 {
  margin-top: 10px;
}

.nav-lower .Dropdown-option:hover {
  text-decoration: underline;
  text-decoration-color: #d6112b;
  color: #d6112b;
}

.nav-lower .Dropdown-option:focus-visible {
  outline: none;
}

.nav-upper {
  height: 48px;
  padding-top: 0;
  margin-bottom: 0;
}

.nav-upper.section {
  margin: 0;
  padding: 20px 20px 0 20px;
}

.right-mrg {
  margin-right: 35px;
}

.search-input,
.search-input:focus-visible {
  border-radius: 50px 0 0 50px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  padding: 9px 20px;
  width: 150;
  font-size: 14px;
  outline: none !important;
}

.search-submit {
  color: white;
  border-radius: 0 50px 50px 0;
  border: 1px solid #d6112b;
  padding: 9px 20px;
  font-size: 14px;
  outline: none !important;
  background-color: #d6112b;
}

.mob-menu form {
  margin: 40px 0;
}

.mob-menu .nav-link {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}

.nav-lower {
  height: 50px;
  padding: 0 20px;
}

.nav-upper {
  padding-bottom: 0;
}

.nav-lower a {
  text-decoration: none;
  font-size: 16px;
}

.nav-lower a:hover {
  text-decoration: underline;
  text-decoration-color: #d6112b;
}

.hamburger-cont {
  display: none;
}

.mob-menu {
  padding-top: 20px;
  background-color: #fff;
  position: fixed;
  margin-top: 75px;
  left: 0;
  top: 0;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transform: translate3d(100vw, 0, 0);

  overflow: scroll;
  width: 100vw;
  height: 100vh;
}

.mob-menu.open {
  transform: translate3d(0, 0, 0);
}

@media only screen and (max-width: 767px) {
  nav .nav-lower,
  nav .tenth .font-m,
  nav .part-45 form,
  nav .tenth {
    display: none;
  }

  .red-bg-hero {
    width: 70%;
  }

  nav .part-45 {
    width: 50%;
  }

  nav {
    top: 0;
  }

  nav .nav-upper.section {
    padding: 15px;
  }

  .hamburger-cont {
    color: #d6112b;
    display: block;
  }
}
