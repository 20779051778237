@media only screen and (max-width: 767px) {
  .section.full.about-1 .half {
    margin-bottom: 0;
  }

  .section.full.about .third.flex {
    margin-bottom: 40px;
  }

  .content .section .half.red-bg {
    height: 600px;
  }

  .content .half.about-img-2 {
    height: 400px;
  }

  .content div.half h4 {
    margin-left: 0;
  }

  .content div.half ul {
    margin-left: 0;
  }

  .medium div.as-top.first {
    margin-top: -100px;
  }

  .medium div.as-top {
    margin-top: 60px;
  }
}

.medium a.default {
  padding: 12px 16px;
  margin-bottom: 20px;
}

.section.full.about-1 {
  padding: 0;
  margin-top: 80px;
}

.half.right-text h4,
.half.right-text ul {
  margin-left: 30px;
}

.section.full.env {
  max-width: 100%;
  height: 400px;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("../assets/env_1.jpg");
  background-size: auto, cover;
  background-position: 0 0, 50% 50%;
  background-repeat: repeat, no-repeat;
}

a.default.env {
  margin-top: 20px;
  display: inline-block;
}

a.default.env.pcn {
  display: inline-block;
  width: 300px;
}

p.eye-catcher {
  font-size: 50px;
  font-family: "Lato Regular";
}

div.as-top {
  margin-top: 40px;
}

div.as-top.first {
  margin-top: 0px;
}

h3.as {
  margin-top: 0;
}

p.body.as {
  width: 90%;
}

.hero.about {
  background-image: url("../assets/about_hero.jpg");
}

.hero.env {
  background-image: url("../assets/env_hero.jpg");
}

.hero.auto-sol {
  background-image: url("../assets/as_hero.jpg");
}

.half.about-img-2 {
  height: 700px;
  background-image: url("../assets/AS_N_5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 50%;
}

.content .half.red-bg {
  background-color: #b02e40;
  color: white;
  height: 700px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content .half.red-bg h2,
.content .half.red-bg h4 {
  margin-left: 20px;
}

.content .half.red-bg h4 {
  font-size: 18px;
  font-family: "Lato Regular";
  font-weight: 400;
  margin-bottom: 0;
}

.content .half.red-bg ul {
  margin-left: 20px;
}

.content .half.red-bg ul li {
  margin-bottom: 20px;
}

.section.full.about {
  min-height: 300px;
  color: white;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65)
    ),
    url("../assets/hero_bg_home.jpg");
  background-position: 0 0, 50% 50%;
}

.section.full.about p {
  font-size: 22px;
  margin: 20px 0 10px 0;
}

.section.full.about .container {
  margin-top: 50px;
}
