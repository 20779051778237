@media only screen and (max-width: 767px) {
  .center-mob {
    justify-content: center;
  }

  .quart {
    width: 100%;
    margin-bottom: 20px;
  }

  .dist-cont div img {
    height: 48px;
  }
}

.first .marg-top {
  margin-top: 20px;
}

.section.less-bot {
  margin-bottom: 0;
}

.section.first {
  margin-bottom: 20px;
}

.dist-country {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 10%);
  color: #2f2d38;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 100px;
  cursor: pointer;
}

.dist-country:hover {
  background-color: #d6112b;
  color: white;
}

div.dist-cont {
  margin-bottom: 30px;
  width: 100%;
}

.dist-cont img {
  height: auto;
  max-width: 90%;
  max-height: 100%;
}

.dist-cont p {
  margin: 0;
}
